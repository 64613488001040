import React, { useCallback, useEffect, useState } from 'react';
import {
  descontoPercentual,
  moeda,
  unidadeMedida,
} from '../../../utils/functions';
import {
  getPriceSmaller,
  priceSmallerPortionView,
  priceSmallerSizeView,
} from '../../../utils/functions-products';
import { FallbackImage } from '../../FallbackImage';
import {
  ContainerProductInfo,
  ProductComposition,
  ProductContainerPrice,
  ProductDescription,
  ProductImage,
  ProductPrice,
  Container,
  ProductUnavailable,
  ProductUnit,
  ProductPriceWrapper,
  PromotionOldPrice,
  PromotionPrice,
  PoroductImageWrapper,
} from './styles';
import { useProduct } from '../../../hooks/useProduct';
import { useGroup } from '../../../hooks/group';
import api from '../../../services/api';
import { getSizesByProduct } from '../../../httpRequests/sizes/getSizesByProduct';
import { useConfig } from '../../../hooks/config';

export const Product = ({ product, productsSizes = [], group = null }) => {
  const { setProduct } = useProduct();
  const { setGroup } = useGroup();
  const { config } = useConfig();
  const [productSizes, setProductSizes] = useState([]);

  const baseUrl = api.getUri();

  const handleClick = useCallback(() => {
    setProduct(product);
    if (config.Layout === 0) {
      sessionStorage.setItem('scroll-catalog', window.scrollY);
    }
    if (group) setGroup(group);
  }, [])

  useEffect(() => {
    if (product.IsPizza) {
      getSizesByProduct(product.Id).then((data) => setProductSizes(data));
    } else {
      setProductSizes(
        productsSizes?.filter((size) => size.IdProduto === product.Id),
      );
    }
  }, []);

  return (
    <Container onClick={handleClick}>
      <PoroductImageWrapper>
        {product.Imagem ? (
          <ProductImage src={`${baseUrl}${product.Imagem}`} />
        ) : (
          <FallbackImage size={88} />
        )}
      </PoroductImageWrapper>
      <ContainerProductInfo>
        <ProductDescription>{product.Descricao}</ProductDescription>
        {product.Unidade && (
          <ProductUnit>{unidadeMedida(product.Unidade)}</ProductUnit>
        )}
        {product.Composicao && (
          <ProductComposition>{product.Composicao}</ProductComposition>
        )}
        {product.Disponivel !== 'False' ? (
          <>
            {product.TipoProduto === 'INDUSTRIALIZADO' && (
              <ProductContainerPrice>
                {!product.HasDesconto && !product.HasDescontoPercentual && (
                  <ProductPrice>{moeda(product.PrecoVenda)}</ProductPrice>
                )}
                {!!product.HasDesconto && (
                  <ProductPriceWrapper>
                    <PromotionPrice>
                      {moeda(product.ValorDesconto)}
                    </PromotionPrice>
                    <PromotionOldPrice>
                      {moeda(product.PrecoVenda)}
                    </PromotionOldPrice>
                  </ProductPriceWrapper>
                )}
                {!!product.HasDescontoPercentual && (
                  <ProductPriceWrapper>
                    <PromotionPrice>
                      {moeda(
                        descontoPercentual(
                          product.PrecoVenda,
                          product.DescontoPercentual,
                        ),
                      )}
                    </PromotionPrice>
                    <PromotionOldPrice>
                      {moeda(product.PrecoVenda)}
                    </PromotionOldPrice>
                  </ProductPriceWrapper>
                )}
              </ProductContainerPrice>
            )}
            {!!product.IsPizza && (
              <>
                {!product.HasDesconto && !product.HasDescontoPercentual && (
                  <ProductContainerPrice>
                    A partir de
                    <ProductPrice>
                      {moeda(getPriceSmaller(productSizes, product).smaller)}
                    </ProductPrice>
                  </ProductContainerPrice>
                )}

                {!!product.HasDesconto && (
                  <ProductContainerPrice>
                    A partir de
                    <ProductPriceWrapper>
                      <PromotionPrice>
                        {moeda(getPriceSmaller(productSizes, product).smaller)}
                      </PromotionPrice>
                      <PromotionOldPrice>
                        {moeda(
                          getPriceSmaller(productSizes, product)
                            .withoutDiscount,
                        )}
                      </PromotionOldPrice>
                    </ProductPriceWrapper>
                  </ProductContainerPrice>
                )}
                {!!product.HasDescontoPercentual && (
                  <ProductContainerPrice>
                    A partir de
                    <ProductPriceWrapper>
                      <PromotionPrice>
                        {moeda(
                          descontoPercentual(
                            getPriceSmaller(productSizes, product).smaller,
                            product.DescontoPercentual,
                          ),
                        )}
                      </PromotionPrice>
                      <PromotionOldPrice>
                        {moeda(getPriceSmaller(productSizes, product).smaller)}
                      </PromotionOldPrice>
                    </ProductPriceWrapper>
                  </ProductContainerPrice>
                )}
              </>
            )}
            {product.TipoProduto === 'PORCAO' && !product.IsPizza && (
              <ProductContainerPrice>
                A partir de
                <ProductPrice>
                  PORCAO
                  {moeda(priceSmallerPortionView(product))}
                </ProductPrice>
              </ProductContainerPrice>
            )}
            {product.TipoProduto === 'PREPARADO' &&
              !product.IsPizza &&
              productSizes?.length > 0 && (
                <ProductContainerPrice>
                  A partir de
                  <ProductPrice>
                    {!product.HasDesconto &&
                      !product.HasDescontoPercentual &&
                      moeda(
                        priceSmallerSizeView(product, productSizes).smallPrice,
                      )}
                    {!!product.HasDesconto && (
                      <>
                        <PromotionPrice>
                          {moeda(
                            priceSmallerSizeView(product, productSizes)
                              .smallPrice,
                          )}{' '}
                        </PromotionPrice>
                        <PromotionOldPrice>
                          {moeda(
                            priceSmallerSizeView(product, productSizes)
                              .withoutDiscount,
                          )}
                        </PromotionOldPrice>
                      </>
                    )}
                    {!!product.HasDescontoPercentual && (
                      <>
                        <PromotionPrice>
                          {moeda(
                            descontoPercentual(
                              priceSmallerSizeView(product, productSizes)
                                .smallPrice,
                              product.DescontoPercentual,
                            ),
                          )}{' '}
                        </PromotionPrice>
                        <PromotionOldPrice>
                          {moeda(
                            priceSmallerSizeView(product, productSizes)
                              .smallPrice,
                          )}
                        </PromotionOldPrice>
                      </>
                    )}
                  </ProductPrice>
                </ProductContainerPrice>
              )}
            {product.TipoProduto === 'PREPARADO' &&
              !product.IsPizza &&
              productSizes.length === 0 && (
                <ProductContainerPrice>
                  <ProductPrice>
                    {!product.HasDesconto &&
                      !product.HasDescontoPercentual &&
                      moeda(product.PrecoVenda)}

                    {!!product.HasDesconto && (
                      <>
                        <PromotionPrice>
                          {moeda(product.ValorDesconto)}
                        </PromotionPrice>
                        <PromotionOldPrice>
                          {moeda(product.PrecoVenda)}
                        </PromotionOldPrice>
                      </>
                    )}

                    {!!product.HasDescontoPercentual && (
                      <>
                        <PromotionPrice>
                          {moeda(
                            descontoPercentual(
                              product.PrecoVenda,
                              product.DescontoPercentual,
                            ),
                          )}
                        </PromotionPrice>
                        <PromotionOldPrice>
                          {moeda(product.PrecoVenda)}
                        </PromotionOldPrice>
                      </>
                    )}
                  </ProductPrice>
                </ProductContainerPrice>
              )}
          </>
        ) : (
          <ProductUnavailable>INDISPONÍVEL</ProductUnavailable>
        )}
      </ContainerProductInfo>
    </Container>
  );
};
