import { useCallback, useEffect, useRef, useState } from 'react';
import { useConfig } from '../../hooks/config';
import { descontoPercentual, getDay } from '../../utils/functions';
import { useProduct } from '../../hooks/useProduct';
import { useGroup } from '../../hooks/group';
import { IdEmpresa } from '../../services/api';
import { notification } from '../../utils/notifications';
import { useSchedules } from '../../hooks/schedules';
import {
  verifyQtNoCost,
  verifyPriceBigerPizza,
  verifyPriceDivided,
  validateObligatoriness,
  validationQuatityminimal,
  verifyQtNotCostAddShoppingCart,
} from '../../utils/functions-products';
import { useCart } from '../../hooks/useCart';
import { FBPixelTrackEvent } from '../../events/FaceBookPixelEvents';
import { getFlavors } from '../../httpRequests/products/getFlavors';
import { useProducts } from '../../hooks/useProducts';
import { getProductsMountings } from '../../httpRequests/products-mountings/getProductsMountings';
import { parseCheckMountings } from './utils';
import { getProductsSizesByCompany } from '../../httpRequests/product-sizes/getProductsSizesByCompany';
import { getAddittionalsByProduct } from '../../httpRequests/products/getAddittionalsByProduct';
import { getBorderByCompany } from '../../httpRequests/products/getBorderByCompany';
import { getProductsSizesByProduct } from '../../httpRequests/product-sizes/getProductSizesByProduct';
import { DELIVERY_TYPES } from '../../entities/delivery-types';
import { getMountingProducts } from '../../httpRequests/mounting-products/getMountingProducts';
import { getAddittionalsByGroup } from 'httpRequests/products/getAddittionalsByGroup';

export const useAddProduct = () => {
  const { product, setProduct } = useProduct();
  const { products } = useProducts();
  const { addItem } = useCart();
  const { group, setGroup, setPreviewsPageHome, previewsPageHome } = useGroup();
  const { schedules } = useSchedules();
  const { config } = useConfig();

  const [quantity, setQuantity] = useState(0);
  const [mountings, setMountings] = useState([]);
  const [size, setSize] = useState(null);
  const [totalProduct, setTotalProduct] = useState(0);
  const [unavailable, setUnavailable] = useState(false);
  const [unavailableMessage, setUnavailableMessage] = useState(null);
  const [additionalsByGroup, setAdditionalsByGroup] = useState([]);
  const [additionalsByProduct, setAdditionalsByProduct] = useState([]);
  const additionals =
    additionalsByGroup.length > 0 ? additionalsByGroup : additionalsByProduct;

  const [checkMountings, setCheckMountings] = useState([]);
  const [flavors, setFlavors] = useState([]);
  const [observations, setObservations] = useState('');
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [borders, setBorders] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);

  const flavorsRef = useRef(null);
  const bordersRef = useRef(null);
  const additionalRef = useRef(null);
  const mountingsRefs = useRef([]);

  const selectedSize = sizes?.find((_size) => _size.Descricao === size);

  const loadSizes = useCallback(() => {
    if (!product?.Id || product.Id === 'undefined') return;

    getProductsSizesByProduct(product.Id).then((data) => {
      setSizes(data);
    });
  }, [product.Id]);

  async function handleGetFlavors() {
    const newFlavors = await getFlavors(product, group, companySizes);
    setFlavors(newFlavors);
  }

  const handleSetAdditionals = (prevState, data) => {
    const additionalsMap = new Map();
    const list = prevState.concat(data);

    list.forEach((item) => additionalsMap.set(item.idProd, item));

    const result = [...additionalsMap.values()];

    return result;
  };

  const setAdditionals = (newState) => {
    if (additionalsByGroup.length > 0) {
      return setAdditionalsByGroup(newState);
    }

    setAdditionalsByProduct(newState);
  };

  const loadAdditionalsMountingsAndFlavors = useCallback(() => {
    getAddittionalsByProduct(product.Id).then((data) => {
      setAdditionalsByProduct((prevState) =>
        handleSetAdditionals(prevState, data),
      );
    });

    getAddittionalsByGroup(product.Grupo).then((data) => {
      setAdditionalsByGroup((prevState) =>
        handleSetAdditionals(prevState, data),
      );
    });

    handleGetFlavors();

    getBorderByCompany().then((data) => {
      const filteredByGroup = data?.filter(
        (item) => item?.group && item.group === product?.Grupo,
      );
      setBorders(filteredByGroup);
    });
  }, [product]);

  const increment = useCallback(() => {
    if (product.Fracionado === 'True') {
      setQuantity((prevState) => parseFloat(prevState + 0.2).toFixed(1));
      return;
    }

    setQuantity((prevState) => prevState + 1);
  }, [setQuantity, product]);

  const decrement = useCallback(() => {
    if (quantity <= 1) {
      return;
    }

    if (product.Fracionado === 'True') {
      setQuantity((prevState) => parseFloat(prevState - 0.2).toFixed(1));
      return;
    }

    setQuantity((prevState) => prevState - 1);
  }, [setQuantity, quantity]);

  const update = useCallback(async () => {
    if (
      product.Disponivel === 'False' ||
      product.Disponivel === false ||
      product.Disponivel === 0
    ) {
      setUnavailable(true);
      setUnavailableMessage('PRODUTO INDISPONÍVEL');
      return;
    }

    let confirm = false;

    const isOpen = getDay(schedules);

    if (config.VendaLojaFechada === 'True' && !isOpen) {
      confirm = !isOpen && config.VendaLojaFechada === 'True';
    } else {
      confirm = isOpen;
    }

    if (
      config.TipoEntregaCheck &&
      (config.TipoEntregaCheck.includes(DELIVERY_TYPES.schedule_delivery) ||
        config.TipoEntregaCheck.includes(DELIVERY_TYPES.schedule_take_local))
    ) {
      confirm = true;
    }

    if (!config.TipoEntregaCheck?.includes(DELIVERY_TYPES.view)) {
      confirm = true;
    }

    if (product.Fracionado === 'True') {
      product.KgInicial
        ? setQuantity(parseFloat(product.KgInicial))
        : setQuantity(0.5);
    } else {
      setQuantity(1);
    }

    if (!confirm) {
      setUnavailable(true);
      setUnavailableMessage('LOJA FECHADA');
      return;
    }
  }, [
    product,
    setUnavailable,
    setUnavailableMessage,
    config,
    setQuantity,
    setUnavailable,
    setUnavailableMessage,
  ]);

  const getPriceProdut = (type) => {
    const percent = product.DescontoPercentual;

    if (type === 'INDUSTRIALIZADO') {
      if (product?.HasDesconto) {
        return Number(product?.ValorDesconto);
      }

      if (product?.HasDescontoPercentual) {
        return descontoPercentual(Number(product?.PrecoVenda), percent);
      }

      return Number(product.PrecoVenda);
    }

    if (type === 'PREPARADO' && !product.IsPizza) {
      if (sizes?.length > 0) {
        const currentSize = sizes.find(
          (filterSize) => filterSize.Descricao === size,
        );

        if (product?.HasDesconto) {
          return currentSize?.PrecoDesconto;
        }

        if (product?.HasDescontoPercentual) {
          return descontoPercentual(currentSize?.Preco, percent);
        }

        return currentSize?.Preco;
      }

      if (product?.HasDesconto) {
        return Number(product.ValorDesconto);
      }

      if (product?.HasDescontoPercentual) {
        return descontoPercentual(product.PrecoVenda, percent);
      }

      return Number(product.PrecoVenda);
    }

    if (type === 'PREPARADO' && product.IsPizza) {
      const flavorsSizesPrices = flavors
        .filter(({ check }) => check)
        .map((flavor) =>
          flavor.sizesPrices.find((sp) => sp.Descricao === size),
        );

      const currentSize = sizes.find(
        (filterSize) => filterSize.Descricao === size,
      );

      const newPrices = [currentSize, ...flavorsSizesPrices]
        .map((sizePrice) => {
          const currentProduct = products.find(
            ({ Id }) => Id === sizePrice?.IdProduto,
          );

          if (currentProduct?.HasDesconto) {
            return sizePrice?.PrecoDesconto;
          }

          if (currentProduct?.HasDescontoPercentual) {
            return descontoPercentual(
              sizePrice?.Preco,
              currentProduct.DescontoPercentual,
            );
          }
          return sizePrice?.Preco ?? 0;
        })
        .filter(Boolean);

      const chargeHigherPrice = config.CobrarValorMaior;

      if (chargeHigherPrice) {
        const findBiggerReducer = (previous, current) => {
          return +current > +previous ? +current : +previous;
        };

        return newPrices.reduce(findBiggerReducer, 0);
      }

      const sumReducer = (sum, curr) => {
        return +sum + +curr;
      };
      const totalPizza = newPrices.reduce(sumReducer, 0) / newPrices.length;

      return totalPizza ?? 0;
    }

    return 0;
  };

  const valueProduct = () => {
    let total = 0;
    let additional = 0;
    let mounting = 0;

    total += parseFloat(getPriceProdut(product.TipoProduto) * quantity);

    additionals.forEach((add) => {
      if (add.check) {
        additional += parseFloat(add.preco * (add?.amount ?? 1) * quantity);
      }
    });

    mountings.forEach((mont) => {
      mounting += +mont.ControlaEmbalagem
        ? verifyQtNoCost(mont, checkMountings, size)
        : verifyQtNoCost(mont, checkMountings, size) * quantity;
    });

    const bordersPrice = () => {
      const prices = borders
        ?.find((border) => border.check)
        ?.tamanhos?.find((tam) => tam.IdTamanho === selectedSize.IdTamanho);

      const borderPrice =
        Number(prices?.PrecoDesconto) > 0
          ? prices?.PrecoDesconto
          : prices?.Preco;

      return parseFloat(borderPrice ?? 0);
    };
    setTotalProduct(total + additional + mounting + bordersPrice());
  };

  const addShoppingCart = useCallback(() => {
    const checkedAdditionals = additionals.filter((x) => x.check);
    const checkedFalvors = flavors.filter((x) => x.check);
    let checkedBorder = borders.find((x) => x.check);

    checkedBorder = {
      ...checkedBorder,
      tamanho: checkedBorder?.tamanhos?.find(
        (tam) => tam.IdTamanho === selectedSize.IdTamanho,
      ),
    };

    let priceProduct = getPriceProdut(product.TipoProduto);

    if (config.CobrarValorMaior === 'True' && product.TipoProduto === 'PIZZA') {
      const priceBiger = verifyPriceBigerPizza(
        size,
        checkedFalvors,
        priceProduct,
      );
      priceProduct = priceBiger;
    } else if (
      config.CobrarValorMaior !== 'True' &&
      product.TipoProduto === 'PIZZA'
    ) {
      const priceDivided = verifyPriceDivided(
        size,
        checkedFalvors,
        priceProduct,
      );
      priceProduct = priceDivided;
    }

    let finalMountings = [];

    mountings.forEach((mount) => {
      const item = verifyQtNotCostAddShoppingCart(mount, checkMountings, size);
      if (item.length > 0) {
        finalMountings.push(...item);
      }
    });

    const item = {
      idEmpresa: IdEmpresa,
      idProduto: product.Id,
      descricao: product.Descricao,
      image: product.Imagem,
      unidade: product.Unidade,
      quantidade: quantity,
      tipo: product.TipoProduto,
      preco: priceProduct,
      hasDesconto: product?.HasDesconto,
      HasDescontoPercentual: product?.HasDescontoPercentual,
      tamanho: size,
      obs: observations,
      composicao: product.Composicao,
      adicionais: checkedAdditionals,
      border: checkedBorder,
      freteGratis: product.FreteGratis,
      fracionado: product.Fracionado,
      kgInicial: parseFloat(product.KgInicial ?? 0),
      sabores: checkedFalvors,
      montagem: finalMountings,
      qtdMinima: parseFloat(product.QtdMinima ?? null),
      idGrupo: group.Id,
      grupoUsaHorario: group.UsaHorario,
      IsPizza: product.IsPizza,
    };

    FBPixelTrackEvent.addToCart({
      contents: [{ id: product.Id, quantity, name: product.Descricao }],
    });

    addItem(item);
    setProduct(null);
    setGroup(null);
    setPreviewsPageHome(null);
  }, [
    size,
    quantity,
    product,
    borders,
    observations,
    checkMountings,
    additionals,
    flavors,
    IdEmpresa,
  ]);

  const validateData = () => {
    const mountingsObligatories = mountings.filter((x) => x.QtdMin > 0);

    const validationObligatories = validateObligatoriness(
      mountingsObligatories,
      checkMountings,
    );

    if (!validationObligatories) {
      notification('warn', 'Opção(ões) obrigatória(s)');
      return false;
    }

    const validateMinimum = validationQuatityminimal(
      mountings,
      checkMountings,
      mountingsObligatories,
    );

    if (mountingsObligatories.length > 0 && !validateMinimum) {
      notification('warn', 'Quantidade minima não atingida');
      return false;
    }

    if (parseFloat(product.QtdMinima) > quantity) {
      notification('warn', `Quantidade minima: ${product.QtdMinima}`);
      return false;
    }

    if (product.IsPizza && !size) {
      notification('warn', 'Selecione o tamanho da pizza');
      return false;
    }

    if (
      product.TipoProduto === 'PREPARADO' &&
      !product.IsPizza &&
      sizes.length > 0 &&
      !size
    ) {
      notification('warn', 'Selecione o tamanho');
      return false;
    }

    return true;
  };

  const addProduct = () => {
    const isDataValid = validateData();

    if (isDataValid) {
      addShoppingCart();
    }
  };

  const loadMountings = useCallback(async () => {
    getProductsMountings(product.Id).then((data) => {
      setMountings(data);
    });

    getMountingProducts(product.Id).then((data) => {
      setCheckMountings(parseCheckMountings(data, quantity));
    });
  }, []);

  const handleBack = () => {
    setProduct(null);
    if (previewsPageHome) {
      setGroup(null);
      setPreviewsPageHome(null);
    }
    if (config.Layout === 0) {
      setGroup(null);
      setPreviewsPageHome(true);
    }
  };

  const selectSize = (value) => {
    setSize(value);
    flavorsRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setCheckMountings((state) =>
      state.map((item) =>
        !+item.controlaEmbalagem
          ? item
          : { ...item, quantidade: parseInt(quantity) },
      ),
    );
  }, [quantity, product]);

  useEffect(() => {
    loadSizes();
    loadAdditionalsMountingsAndFlavors();
    setLoading(false);
    update();
  }, [product]);

  useEffect(() => {
    if (companySizes?.length > 0) {
      setFlavors(
        flavors.map((flavor) => ({
          ...flavor,
          sizesPrices: companySizes?.filter(
            (s) => flavor.idProd === s.IdProduto,
          ),
        })),
      );
    }
  }, [companySizes]);

  useEffect(() => {
    valueProduct();
  }, [quantity, size, checkMountings, additionals, flavors, sizes, borders]);

  useEffect(() => {
    loadMountings();

    getProductsSizesByCompany().then((data) => {
      setCompanySizes(data);
    });
  }, []);

  return {
    size,
    sizes,
    group,
    config,
    loading,
    borders,
    product,
    flavors,
    quantity,
    mountings,
    additionals,
    bordersRef,
    flavorsRef,
    unavailable,
    observations,
    totalProduct,
    selectedSize,
    mountingsRefs,
    additionalRef,
    checkMountings,
    unavailableMessage,
    selectSize,
    decrement,
    increment,
    addProduct,
    handleBack,
    setFlavors,
    setBorders,
    setMountings,
    setAdditionals,
    setObservations,
    setCheckMountings,
  };
};
