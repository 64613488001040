import React from 'react';
import {
  MdCalendarToday,
  MdOutlineAccessTime,
  MdOutlineAttachMoney,
  MdOutlinePayments,
  MdRefresh,
} from 'react-icons/md';
import { formatDate, moeda } from '../../utils/functions';
import {
  ContainerDataDelivery,
  ContainerDeliveries,
  ContainerDelivery,
  ContainerInfoDelivery,
  ContainerPriceDelivery,
  DataDelivery,
  HourDelivery,
  NumberDelivery,
  PaymentDelivery,
  StatusDelivery,
} from './styles';
import { useTheme } from 'styled-components';
import { Pagination } from '../Pagination/Pagination';

const ListDeliveries = ({ list, showOrder, page, handlePrev, handleNext }) => {
  const { colors } = useTheme();

  const getStatusOrder = (status, AsaasPaymentId, AsaasConfirmPayment) => {
    if (AsaasPaymentId && !AsaasConfirmPayment) {
      return 'AGUARDANDO PAGAMENTO'
    }

    if (AsaasPaymentId && AsaasConfirmPayment) {
      return 'PAGO ONLINE'
    }

    switch (status) {
      case 'PENDENTE':
        return 'AGUARDANDO ACEITAÇÃO';
      case 'ANDAMENTO':
        return 'EM PRODUCAO';
      case 'ENTREGA':
        return 'SAIU PARA ENTREGA';
      case 'CONCLUIDO':
        return 'ENTREGA REALIZADA';
      default:
        return 'CANCELADO';
    }
  };

  const getStatusColor = (status, AsaasPaymentId, AsaasConfirmPayment) => {
    if (AsaasPaymentId && !AsaasConfirmPayment) {
      return 'PENDENTEPAG'
    }
    if (AsaasPaymentId && AsaasConfirmPayment) {
      return 'PAGO'
    }
    return status
  };

  return (
    <ContainerDeliveries>
      {list?.map((delivery) => (
        <ContainerDelivery
          key={delivery.Id}
          onClick={() => showOrder(delivery)}
        >
          <NumberDelivery>Nº {delivery.Codigo}</NumberDelivery>
          <ContainerDataDelivery>
            <DataDelivery>
              <MdCalendarToday color={colors.main} size={15} />{' '}
              {formatDate(delivery.DataPedido)}
            </DataDelivery>
            <HourDelivery>
              {' '}
              <MdOutlineAccessTime color={colors.main} size={15} />{' '}
              {delivery.Hora}
            </HourDelivery>
          </ContainerDataDelivery>
          <ContainerInfoDelivery>
            <StatusDelivery status={
              getStatusColor(
                delivery.Status,
                delivery.AsaasPaymentId,
                delivery.AsaasConfirmPayment,
              )}
            >
              <MdRefresh color={colors.main} size={15} />{' '}
              {getStatusOrder(
                delivery.Status,
                delivery.AsaasPaymentId,
                delivery.AsaasConfirmPayment,
              )}
            </StatusDelivery>
            <PaymentDelivery>
              <MdOutlinePayments color={colors.main} size={15} />{' '}
              {delivery.FormaPagamento}
            </PaymentDelivery>
          </ContainerInfoDelivery>
          <ContainerPriceDelivery>
            <MdOutlineAttachMoney color={colors.main} size={15} />{' '}
            {moeda(
              parseFloat(delivery.ValorTotal) +
              parseFloat(delivery.TaxaAdministracao) +
              parseFloat(delivery.TaxaEntrega),
            )}
          </ContainerPriceDelivery>
        </ContainerDelivery>
      ))}
      <Pagination page={page} prev={handlePrev} next={handleNext} />
    </ContainerDeliveries>
  );
};

export default ListDeliveries;
