import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FinishChange,
  FinishContainer,
  FinishCoupom,
  FinishCoupomButton,
  FinishFormContainer,
  FinishFormTitle,
  FinishPaymentAccount,
  FinishPaymentsAccountContainer,
  FinishTaxeContainer,
  FinishTitle,
  FinishTotalContainer,
  FinishTotalsContainer,
  FinishType,
  PaymentAccountBank,
  PaymentAccountData,
  PaymentAccountInfo,
  TaxeData,
  TitleProof,
  TotalData,
  NoDeliveryTypes,
  Container,
  StepsContainer,
  ButtonAddAddressContainer,
} from './styles';
import { mask, unMask } from 'remask';
import { FaShippingFast } from 'react-icons/fa';
import { useConfig } from '../../hooks/config';
import Input from '../../components/Input';
import RadioGroup from '../../components/RadioGroup';
import Dropdown from '../../components/Dropdown';
import api from '../../services/api';
import { notification, notifications } from '../../utils/notifications';
import { useCart } from '../../hooks/useCart';
import { getDay, logout, moeda } from '../../utils/functions';
import AddressInsert from './AddressInsert';
import { useSchedules } from '../../hooks/schedules';
import CoupomInsert from './CoupomInsert';
import { useAuth } from '../../hooks/auth';
import { useCompany } from '../../hooks/useCompany';
import { FBPixelTrackEvent } from '../../events/FaceBookPixelEvents';
import { PaymentModal } from '../../components/PaymentModal/PaymentModal';
import Loading from '../../components/Loading';
import { getDeliveryTypes } from './utils/getDeliveryTypes';
import { Spacer } from '../../components/Spacer';
import { Text } from '../../components/Text/Text';
import { FiAlertTriangle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { useTab } from '../../hooks/tabIndex';
import { DELIVERY_TYPES } from '../../entities/delivery-types';
import { addDays, format, set } from 'date-fns';
import { getMinMaxTime } from './utils/getCurrentSchedule';
import { daysOfWeek } from '../../utils/constants/daysOfWeekList';
import { screens } from 'routes/Tabs/screens';
import { onSubmitOrder } from './utils/submitOrder';
import { getAvailableUserGoal } from '../../httpRequests/goal/getAvailableUserGoal';
import { GoalCard } from './components/GoalCard/GoalCard';
import { Box } from '../../components/Box/Box';
import { getPrizeValue } from './utils/getPrizeValue';
import FinishOrderSteps from './components/Steps';
import PaymentsTabs from './components/PaymentTabs';
import AddAddress from '../Account/Address/Add';
import Button from 'components/Button';

const handlePaymentIsMoney = ({ payments, payment }) => {
  const paymentMode = payments.find((pay) => {
    return (
      !Number.isNaN(Number(pay?.Id)) && Number(pay?.Id) === Number(payment)
    );
  });

  const paymentDescription = String(paymentMode?.Descricao);
  return paymentDescription.toLowerCase().includes('dinheiro');
};

const handlePaymentIsPix = ({ payments, payment }) => {
  const paymentMode = payments.find((pay) => {
    return (
      !Number.isNaN(Number(pay?.Id)) && Number(pay?.Id) === Number(payment)
    );
  });

  const paymentPix = paymentMode?.IdPagamento === 12;
  return paymentPix;
};

const handleTaxeAdm = ({ payments, payment }) => {
  const pay = payments.find((x) => +x.Id === +payment);
  return +pay.TaxaAdministracao;
};

const FinishOrder = () => {
  const { finish, handleFinish, setTab } = useTab();
  const { user } = useAuth();
  const { company } = useCompany();
  const IdEmpresa = company?.NrContrato;
  const { config } = useConfig();
  const { schedules, isOpen } = useSchedules();
  const { colors } = useTheme();
  const { total, cart, clearCart } = useCart();

  const [paymentsAccounts, setPaymentsAccounts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [taxeFreeDeliveryRegion, setTaxeFreeDeliveryRegion] = useState([]);
  const [neighborhoodId, setNeighborhoodId] = useState(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [typeDelivery, setTypeDelivery] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [address, setAddress] = useState(null);
  const [number, setNumber] = useState(null);
  const [complement, setComplement] = useState(null);
  const [coupom, setCoupom] = useState(null);
  const [change, setChange] = useState(0);
  const [proof, setProof] = useState(null);
  const [discont, setDiscont] = useState(0);
  const [taxeDelivery, setTaxeDelivery] = useState(0);
  const [taxeDrawal, setTaxeDrawal] = useState(0);
  const [taxeFree, setTaxeFree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myaddress, setMyAddress] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const [openCoupom, setOpenCoupom] = useState(false);
  const isSubmiting = useRef(false);
  const [payment, setPayment] = useState(null);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [payments, setPayments] = useState([]);
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [goal, setGoal] = useState(null);
  const [useGoal, setUseGoal] = useState(false);
  const [steps, setSteps] = useState(0);
  const [deliveryId, setDeliveryId] = useState(null);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [taxeAdm, setTaxeAdm] = useState(0);

  const minMaxTime = getMinMaxTime(scheduleDate, scheduleTime, schedules);
  const prize = getPrizeValue(goal, total, taxeDelivery);

  const orderTotal = total + taxeDelivery + taxeDrawal + taxeAdm;
  const orderLiquidTotal = orderTotal - discont - (useGoal ? prize : 0);
  const showOrderTotal = orderLiquidTotal > 0 ? orderLiquidTotal : 0;

  const isPaymentMoney = handlePaymentIsMoney({
    payments,
    payment: paymentMethod,
  });

  const isPaymentPix = handlePaymentIsPix({
    payments,
    payment: paymentMethod,
  });

  const deliveryTypes = getDeliveryTypes(config, isOpen);

  const resetFinishOrder = () => {
    setPaymentMethod(null);
  };

  const loadUserLocal = useCallback(async () => {
    if (user?.Id) return;

    const userLocalStorage = localStorage.getItem('user');

    if (!userLocalStorage) return;

    try {
      const userLocal = JSON.parse(userLocalStorage);

      setName(userLocal.name);
      setPhone(userLocal.phone);
      if (userLocal.address) {
        setAddress(userLocal.address.address);
        setNumber(userLocal.address.number);
        setComplement(userLocal.address.complement);
      }
    } catch {
      return;
    }
  }, [neighborhoods]);

  const update = useCallback(async () => {
    await api
      .get(`/bairro/listar/${IdEmpresa}`)
      .then((response) => {
        const neib = response.data.map((obj) => {
          return {
            value: obj.Id,
            label: `${obj.Bairro}`,
            cobraTaxa: obj.CobraTaxa,
            taxaEntrega: obj.TaxaEntrega,
            prevalenciaTaxaBairro: obj.PrevalenciaTaxaBairro,
          };
        });
        setNeighborhoods(neib);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar os bairros. ${error?.response?.data?.message}`,
          error,
        );
      });

    api
      .get(`/forma-pagamento-empresa/listar/${IdEmpresa}`)
      .then((response) => {
        setPayments([
          ...response.data,
          {
            Id: 0,
            IdEmpresa: company.NrContrato,
            IdPagamento: 99,
            Descricao: 'PIX',
          },
        ]);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar as formas de pagamento. ${error?.response?.data?.message}`,
          error,
        );
      });

    api
      .get(`/conta/listar/${IdEmpresa}`)
      .then((response) => setPaymentsAccounts(response.data))
      .catch((error) => {
        console.log(
          `Erro ao carregar as contas para transferência. ${error?.response?.data?.message}`,
          error,
        );
      });

    api
      .get(`/regiao-bairro/listarTaxas/${IdEmpresa}`)
      .then((response) => {
        setTaxeFreeDeliveryRegion(response.data);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar as taxas. ${error?.response?.data?.message}`,
          error,
        );
      });
  }, [IdEmpresa]);

  const loadMyAddress = useCallback(async () => {
    if (user?.Id) {
      api
        .get(`/endereco/selecionar/${user?.Id}/${IdEmpresa}`)
        .then((res) => {
          setMyAddress(res.data);
        })
        .catch((error) => {
          console.log(
            error,
            `Erro ao carregar seus endereços. ${error?.response?.data?.message}`,
          );
        });
    } else {
      logout();
    }
  }, [user]);

  const selectMyAddress = useCallback(
    (id) => {
      const addrss = myaddress.find((x) => x.Id === id);
      let taxe = 0,
        free = false;
      const neibhs = neighborhoods.find(
        (x) => x.value === addrss?.Bairro && x.cobraTaxa === 'S',
      );

      taxe = neibhs ? neibhs.taxaEntrega : 0;

      if (neibhs?.prevalenciaTaxaBairro) {
        setAddressId(id);
        setTaxeDelivery(taxe);
        setTaxeFree(false);
        return;
      }

      if (config.FreteGratis && config.FreteGratis > 0) {
        if (total >= parseFloat(config.FreteGratis)) {
          taxe = 0;
          free = true;
        }
      } else if (taxeFreeDeliveryRegion.length > 0) {
        taxeFreeDeliveryRegion.forEach((region) => {
          if (
            region.IdBairro === id &&
            total >= parseFloat(region.TaxaGratis)
          ) {
            taxe = 0;
            free = true;
          }
        });
      }
      const productFreeDelivery = cart.find((x) => x.freteGratis);
      if (productFreeDelivery) {
        taxe = 0;
        free = true;
      }

      if (+config.TaxaEntregaPercentual) {
        let value = taxe;
        taxe = (total * value) / 100;
      }

      setAddressId(id);
      setTaxeDelivery(taxe);
      setTaxeFree(free);
    },
    [myaddress, cart],
  );

  const verifyAvailability = useCallback(async () => {
    let unavlb = [];
    cart?.forEach(async (item) => {
      await api.get(`/produto/encontrar/${item.idProduto}`).then((response) => {
        if (response.data.Disponivel === 'False') {
          unavlb.push(response.data);
        }
      });
    });
    if (unavlb.length) {
      return true;
    }
    return false;
  }, []);

  const fileSelect = useCallback((event) => {
    setProof(event.target.files[0]);
  }, []);

  const handlePurchaseEvents = () => {
    FBPixelTrackEvent.purchase({
      currency: 'BRL',
      value: total,
      contents: cart.map((item) => ({
        id: item?.idProduto,
        quantity: item?.quantidade,
        name: item?.descricao,
        value: item?.preco,
      })),
    });
  };

  const stopSubmiting = () => {
    isSubmiting.current = false;
    setLoading(false);
  };

  const onClose = (orderId) => {
    clearCart();
    resetFinishOrder();
    handlePurchaseEvents();
    localStorage.removeItem('produtos');
    handleFinish(false);
    setTab(user ? screens.account : screens.consult_order, { orderId });
  };

  const onChangePayment = (paymentData) => {
    if (paymentData) {
      setPayment(paymentData);
      setPaymentOpen(true);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    await onSubmitOrder({
      user,
      myaddress,
      typeDelivery,
      addressId,
      scheduleDate,
      scheduleTime,
      payments,
      paymentMethod,
      change,
      IdEmpresa,
      phone,
      address,
      neighborhoodId,
      number,
      complement,
      taxeDelivery,
      taxeDrawal,
      discont,
      total,
      proof,
      cart,
      coupom,
      config,
      name,
      goal,
      useGoal,
      onStop: stopSubmiting,
      onClose,
      onChangePayment,
      setDeliveryId,
    });
  };

  const handlePayment = (e) => {
    setPaymentMethod(e);

    const isMoney = handlePaymentIsMoney({ payments, payment: e });

    if (!isMoney) {
      setChange(0);
    }

    const taxe = handleTaxeAdm({ payments, payment: e });
    setTaxeAdm(taxe ?? 0);
  };

  const handleSubmitOrder = async () => {
    if (loading || isSubmiting.current) {
      return;
    }

    isSubmiting.current = true;
    setLoading(true);

    if (!paymentMethod) {
      notifications.error(`Forma de pagamento não informada!`);
      return stopSubmiting();
    }

    const sellWhenClosed = Boolean(
      config.VendaLojaFechada === 'True' ||
      config.VendaLojaFechada === 1 ||
      config.VendaLojaFechada === true,
    );

    const scheduling =
      config.TipoEntregaCheck?.includes(DELIVERY_TYPES.schedule_delivery) ||
      config.TipoEntregaCheck?.includes(DELIVERY_TYPES.schedule_take_local);

    if (!isOpen && !sellWhenClosed && !scheduling) {
      notifications.error(`Loja fechada`);
      return stopSubmiting();
    }

    const productsUnavailable = await verifyAvailability();

    if (productsUnavailable) {
      handleFinish(false);
      return stopSubmiting();
    }

    handleSubmit();
  };

  const closePayment = () => {
    setPaymentOpen(false);
    onClose();
  };

  const getSchedulesDatesAvailable = (settings) => {
    const schedulesDays = settings?.DiasAgendamento
      ? JSON.parse(settings?.DiasAgendamento)
      : {};

    return schedulesDays;
  };

  const onChangeDate = (event) => {
    const newDate = event.target.value;
    const comparativeDate = new Date(`${newDate} ${scheduleTime}`);
    const schedulesAvailableDates = getSchedulesDatesAvailable(config);

    if (!schedulesAvailableDates[daysOfWeek[comparativeDate.getDay()]]) {
      return notifications.error('Data não disponivel para agendamento');
    }

    setScheduleDate(newDate);
    setScheduleTime('');
  };

  const onChangeTime = (event) => {
    const newTime = event.target.value;
    const newDateTime = new Date(scheduleDate + ' ' + newTime);
    const min = new Date(scheduleDate + ' ' + format(minMaxTime.min, 'HH:mm'));
    const max = new Date(scheduleDate + ' ' + format(minMaxTime.max, 'HH:mm'));

    if (newDateTime < new Date()) {
      return notifications.error(`Hora não pode ser menor que hora atual`);
    }

    if (newDateTime < min) {
      return notifications.error(
        `Hora não pode ser menor que ${format(min, 'HH:mm')}`,
      );
    }
    if (newDateTime > max) {
      return notifications.error(
        `Hora não pode ser maior que ${format(max, 'HH:mm')}`,
      );
    }

    setScheduleTime(newTime);
  };

  const parseChangeValue = (value) => {
    const strValue = String(value);
    const cleanValue = strValue.replaceAll(',', '').replaceAll('.', '');
    const filledValue = String(+cleanValue).padStart(3, '0');
    const newValue = filledValue.slice(0, -2) + ',' + filledValue.slice(-2);
    return newValue;
  };

  const handleChange = (e) => {
    const value = String(e.target.value);
    const cleanValue = value.replaceAll(',', '').replaceAll('.', '');
    setChange(Number(cleanValue));
  };

  const getUserGoals = async () => {
    const userGoal = await getAvailableUserGoal({
      companyId: IdEmpresa,
      userId: user?.Id,
      phone,
    });
    setGoal(userGoal);
  };

  useEffect(() => {
    if (coupom) {
      let disct = 0;
      if (coupom.TipoDesconto === 1) {
        disct = (parseFloat(coupom.Valor) / 100) * total;
      } else {
        disct = parseFloat(coupom.Valor);
      }
      setDiscont(disct);
    }
  }, [coupom]);

  useEffect(() => {
    update();
    loadMyAddress();
  }, [finish]);

  useEffect(() => {
    if (['RE', 'AGRE'].includes(typeDelivery)) {
      setTaxeDelivery(0);
      setNeighborhoodId(null);
      selectMyAddress(null);
      setAddress(null);
      setNumber(null);
      setComplement(null);
    }
    if (['RE', 'AGRE'].includes(typeDelivery) && config.TaxaRetirada > 0) {
      if (config.TaxaRetiradaPercentual) {
        let value = config.TaxaRetirada;
        let taxe = (total * value) / 100;
        setTaxeDrawal(taxe);
      } else {
        setTaxeDrawal(config.TaxaRetirada);
      }
    } else {
      setTaxeDrawal(0);
    }
  }, [typeDelivery]);

  useEffect(() => {
    loadUserLocal();
  }, [neighborhoods]);

  useEffect(() => {
    if (user) {
      getUserGoals();
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <FinishContainer>
          <FinishTitle>Finalizar Pedido</FinishTitle>
          <FinishFormContainer>
            <FinishOrderSteps
              steps={steps}
              setSteps={setSteps}
              childs={[
                {
                  title: 'Seus Dados',
                  show: !user?.Id,
                  showOptions: true,
                  textButtonBack: 'Fechar',
                  onClickBack: () => {
                    handleFinish(false);
                  },
                  onClickNext: () => {
                    if (name && phone) setSteps((state) => state + 1);
                    else notification('warn', 'Preecha os campos.');
                  },
                  child: (
                    <StepsContainer>
                      <FinishFormTitle>Informe seus dados</FinishFormTitle>
                      <Input
                        placeholder="Informe seu nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullScreen
                      />
                      <Spacer y={8} />
                      <Input
                        placeholder="Informe seu WhatsApp"
                        value={phone}
                        onChange={(e) =>
                          setPhone(
                            mask(unMask(e.target.value), ['(99)99999-9999']),
                          )
                        }
                        fullScreen
                      />
                    </StepsContainer>
                  ),
                },
                {
                  title: 'Entrega',
                  show: true,
                  showOptions: true,
                  onClickBack: () => {
                    if (user?.Id) handleFinish(false);
                    else setSteps((state) => state - 1);
                  },
                  onClickNext: () => {
                    if (['RE', 'LOCAL'].includes(typeDelivery)) {
                      setSteps((state) => state + 2);
                    } else if (['EN'].includes(typeDelivery)) {
                      setSteps((state) => state + 1);
                    } else if (
                      ['AGRE'].includes(typeDelivery) &&
                      !!scheduleDate &&
                      !!scheduleTime
                    ) {
                      setSteps((state) => state + 2);
                    } else if (
                      ['AG'].includes(typeDelivery) &&
                      !!scheduleDate &&
                      !!scheduleTime
                    ) {
                      setSteps((state) => state + 1);
                    } else {
                      notification('warn', 'Selecione o tipo da entrega.');
                    }
                  },
                  child: (
                    <FinishType>
                      <FinishFormTitle>Forma de entrega</FinishFormTitle>
                      {deliveryTypes.length > 0 && getDay(schedules) ? (
                        <RadioGroup
                          value={typeDelivery}
                          options={deliveryTypes}
                          setValue={setTypeDelivery}
                          position="relative"
                        />
                      ) : (
                        <NoDeliveryTypes>
                          <FiAlertTriangle size={24} color={colors.alert} />
                          <Text variant="info">
                            Não há formas de entregas disponíveis no momento
                          </Text>
                        </NoDeliveryTypes>
                      )}
                      {['AG', 'AGRE'].includes(typeDelivery) && (
                        <>
                          {typeDelivery === 'AGRE' ? 'Retirar' : 'Receber'} em:
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              gap: 8,
                              marginBottom: 12,
                            }}
                          >
                            <Input
                              type="date"
                              value={scheduleDate}
                              min={
                                minMaxTime.max && minMaxTime.max < new Date()
                                  ? format(addDays(new Date(), 1), 'yyyy-MM-dd')
                                  : format(new Date(), 'yyyy-MM-dd')
                              }
                              max={format(addDays(new Date(), 7), 'yyyy-MM-dd')}
                              onChange={onChangeDate}
                            />
                            <Input
                              type="time"
                              disabled={!scheduleDate}
                              value={scheduleTime}
                              onChange={onChangeTime}
                            />
                          </div>
                        </>
                      )}
                    </FinishType>
                  ),
                },
                {
                  title: 'Endereço',
                  show: true,
                  showOptions: true,
                  onClickNext: () => {
                    if (!user?.Id && neighborhoodId && address) {
                      setSteps((state) => state + 1);
                    } else if (
                      user?.Id &&
                      myaddress?.length === 0 &&
                      neighborhoodId &&
                      address
                    ) {
                      setSteps((state) => state + 1);
                    } else if (user?.Id && myaddress?.length > 0 && addressId) {
                      setSteps((state) => state + 1);
                    } else {
                      notification('warn', 'Endereço obrigatório.');
                    }
                  },
                  child: (
                    <>
                      {['EN', 'AG'].includes(typeDelivery) && !user?.Id && (
                        <AddressInsert
                          address={address}
                          setAddress={setAddress}
                          number={number}
                          setNumber={setNumber}
                          complement={complement}
                          setComplement={setComplement}
                          neighborhoods={neighborhoods}
                          neighborhoodId={neighborhoodId}
                          setNeighborhoodId={setNeighborhoodId}
                          setTaxeDelivery={setTaxeDelivery}
                          setTaxeFree={setTaxeFree}
                          taxeFreeDeliveryRegion={taxeFreeDeliveryRegion}
                        />
                      )}

                      {['EN', 'AG'].includes(typeDelivery) && user?.Id && (
                        <>
                          <ButtonAddAddressContainer>
                            <Button onClick={() => setOpenAddAddress(true)}>
                              Cadastrar
                            </Button>
                          </ButtonAddAddressContainer>
                          <FinishFormTitle>
                            Endereço para entrega
                          </FinishFormTitle>
                          <Dropdown
                            list={myaddress}
                            objListId="Id"
                            objListLabel="Descricao"
                            name="addressId"
                            value={addressId}
                            onChange={(e) => selectMyAddress(e.target.value)}
                          />
                        </>
                      )}
                    </>
                  ),
                },
                {
                  title: 'Pagamento',
                  show: true,
                  showOptions: true,
                  onClickNext: () => handleSubmitOrder(),
                  textButtonNext: 'Enviar Pedido',
                  onClickBack: () => {
                    if (['RE', 'LOCAL', 'AGRE'].includes(typeDelivery)) {
                      setSteps((state) => state - 2);
                    } else {
                      setSteps((state) => state - 1);
                    }
                    setTaxeAdm(0);
                    setPaymentMethod(null);
                  },
                  child: (
                    <>
                      <PaymentsTabs
                        description="Forma de pagamento"
                        value={paymentMethod}
                        onChange={handlePayment}
                        payments={payments}
                        tabs={[
                          config?.EnableAsaasPayment && {
                            title: 'Pagamento online',
                            payments: payments.filter(
                              (x) => x.IdPagamento === 99,
                            ),
                          },
                          {
                            title: ['RE', 'LOCAL', 'AGRE'].includes(
                              typeDelivery,
                            )
                              ? 'Pagamento na retirada'
                              : 'Pagamento na entrega',
                            payments: payments.filter(
                              (x) => x.IdPagamento !== 99,
                            ),
                          },
                        ]}
                      />
                      {isPaymentMoney && (
                        <FinishChange>
                          <FinishFormTitle>
                            Preciso de troco para
                          </FinishFormTitle>
                          <Input
                            placeholder="Troco para R$"
                            value={parseChangeValue(change)}
                            onChange={handleChange}
                            fullScreen
                          />
                        </FinishChange>
                      )}

                      <Spacer y={8} />

                      {!coupom && Boolean(config?.MostrarCupom) && (
                        <FinishCoupom>
                          <FinishCoupomButton
                            onClick={() => setOpenCoupom(true)}
                          >
                            Inserir cupom de desconto
                          </FinishCoupomButton>
                        </FinishCoupom>
                      )}

                      {Boolean(goal) && (
                        <GoalCard
                          goal={goal}
                          setUseGoal={(value) => setUseGoal(value)}
                          total={orderTotal}
                        />
                      )}

                      {isPaymentPix && (
                        <>
                          <FinishPaymentsAccountContainer>
                            <FinishFormTitle>
                              Contas para transferência
                            </FinishFormTitle>
                            {paymentsAccounts.map((pay) => (
                              <FinishPaymentAccount key={`payments-${pay.Id}`}>
                                <PaymentAccountBank>
                                  {pay.Banco}
                                </PaymentAccountBank>
                                {!pay.UsaPix ? (
                                  <PaymentAccountInfo>
                                    <PaymentAccountData>
                                      <b>Ag:</b> {pay.Agencia}
                                    </PaymentAccountData>
                                    <PaymentAccountData>
                                      <b>CC:</b> {pay.Conta}
                                    </PaymentAccountData>
                                  </PaymentAccountInfo>
                                ) : (
                                  <PaymentAccountInfo>
                                    <PaymentAccountData>
                                      <b>Pix:</b> {pay.Pix}
                                    </PaymentAccountData>
                                  </PaymentAccountInfo>
                                )}
                                <PaymentAccountData>
                                  <b>Favorecido: </b>
                                  {pay.Favorecido}
                                </PaymentAccountData>
                              </FinishPaymentAccount>
                            ))}
                          </FinishPaymentsAccountContainer>
                          <TitleProof>
                            Anexar Comprovante{' '}
                            <b style={{ color: 'red' }}>(Formato de imagem)</b>
                          </TitleProof>
                          <Input type="file" onChange={fileSelect} />
                        </>
                      )}

                      <FinishTotalsContainer>
                        <FinishTaxeContainer>
                          <TaxeData>Sub total</TaxeData>
                          <TaxeData>{moeda(total)}</TaxeData>
                        </FinishTaxeContainer>
                        {taxeDrawal > 0 && (
                          <FinishTaxeContainer>
                            <TaxeData>Taxa de retirada:</TaxeData>
                            <TaxeData>{moeda(taxeDrawal)}</TaxeData>
                          </FinishTaxeContainer>
                        )}
                        {useGoal && (
                          <Box justifyContent="space-between">
                            <Text variant="info">Resgate Promoção:</Text>
                            <Text variant="info">
                              -{' '}
                              {moeda(
                                Number(prize) > Number(orderTotal)
                                  ? orderTotal
                                  : prize,
                              )}
                            </Text>
                          </Box>
                        )}
                        {!taxeFree ? (
                          <FinishTaxeContainer>
                            <TaxeData>Taxa de entrega:</TaxeData>
                            <TaxeData>{moeda(taxeDelivery)}</TaxeData>
                          </FinishTaxeContainer>
                        ) : (
                          <FinishTaxeContainer>
                            <TaxeData successColor>
                              <FaShippingFast size={13} color="#008000" />{' '}
                              Entrega grátis
                            </TaxeData>
                          </FinishTaxeContainer>
                        )}
                        {coupom && (
                          <FinishTaxeContainer>
                            <TaxeData successColor>Desconto</TaxeData>
                            <TaxeData successColor>{moeda(discont)}</TaxeData>
                          </FinishTaxeContainer>
                        )}
                        {taxeAdm > 0 && (
                          <FinishTaxeContainer>
                            <TaxeData>Taxa de Administração</TaxeData>
                            <TaxeData>{moeda(taxeAdm)}</TaxeData>
                          </FinishTaxeContainer>
                        )}
                        <FinishTotalContainer>
                          <TotalData>Total</TotalData>
                          <TotalData>{moeda(showOrderTotal)}</TotalData>
                        </FinishTotalContainer>
                      </FinishTotalsContainer>
                    </>
                  ),
                },
              ]}
            />
          </FinishFormContainer>
        </FinishContainer>
      </Container>
      {openCoupom && (
        <CoupomInsert
          show={openCoupom}
          close={() => setOpenCoupom(false)}
          setCoupom={setCoupom}
        />
      )}
      {paymentOpen && (
        <PaymentModal
          payment={payment}
          onClose={closePayment}
          amount={showOrderTotal}
          deliveryId={deliveryId}
        />
      )}

      {openAddAddress && (
        <AddAddress
          address={{}}
          setMyAddress={setMyAddress}
          config={config}
          neighborhoods={neighborhoods.map((neigh) => ({
            Id: neigh.value,
            Bairro: neigh.label,
          }))}
          closeModal={() => setOpenAddAddress(false)}
        />
      )}
    </>
  );
};

export default FinishOrder;
